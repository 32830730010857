import icon1 from "@/icons/menu/icon1.svg";
import icon1Active from "@/icons/menu/icon1-active.svg";
import icon2 from "@/icons/menu/icon2.svg";
import icon2Active from "@/icons/menu/icon2-active.svg";
import icon3 from "@/icons/menu/icon3.svg";
import icon3Active from "@/icons/menu/icon3-active.svg";
import icon4 from "@/icons/menu/icon4.svg";
import icon4Active from "@/icons/menu/icon4-active.svg";
import icon5 from "@/icons/menu/icon5.svg";
import icon5Active from "@/icons/menu/icon5-active.svg";
// eslint-disable-next-line no-unused-vars
import icon6 from "@/icons/menu/icon6.svg";
// eslint-disable-next-line no-unused-vars
import icon6Active from "@/icons/menu/icon6-active.svg";
import icon7 from "@/icons/menu/icon7.svg";
import icon7Active from "@/icons/menu/icon7-active.svg";
// import icon8 from "@/icons/menu/icon8.svg";
// import icon8Active from "@/icons/menu/icon8-active.svg";
// import icon9 from "@/icons/menu/icon9.svg";
// import icon9Active from "@/icons/menu/icon9-active.svg";
export const localRoutes = [
  // header导航栏
  {
    actionKey: "menu:exhibit-mange",
    componentPath: "",
    enabled: true,
    icon: "",
    id: 1,
    name: "展会管理",
    path: "/exhibit-manage",
    sequence: 1,
    superiorId: null,
    type: "DIR",
  },
  {
    actionKey: "menu:business-mange",
    componentPath: "",
    enabled: true,
    icon: "",
    id: 2,
    name: "商机管理",
    path: "/business-manage",
    sequence: 2,
    superiorId: null,
    type: "DIR",
  },
  {
    actionKey: "menu:my-mange",
    componentPath: "",
    enabled: true,
    icon: "",
    id: 3,
    name: "个人中心",
    path: "/my",
    sequence: 3,
    superiorId: null,
    type: "DIR",
  },
  // 侧边栏菜单
  // 展会管理
  {
    actionKey: "menu:exhibit-info",
    componentPath: "exhibition/info/InfoPage",
    enabled: true,
    icon: icon1,
    iconActive: icon1Active,
    id: 101,
    name: "公司信息",
    path: "/exhibit-manage/info",
    sequence: 1,
    superiorId: 1,
    type: "MENU",
  },
  {
    actionKey: "menu:exhibit-list",
    componentPath: "exhibition/exhibit/ExhibitList",
    enabled: true,
    icon: icon2,
    iconActive: icon2Active,
    id: 102,
    name: "展品管理",
    path: "/exhibit-manage/exhibit-list",
    sequence: 2,
    superiorId: 1,
    type: "MENU",
  },
  // 商机管理
  {
    actionKey: "menu:supply-demand-manage",
    componentPath: "business/supply-demand/SupplyDemandPage",
    enabled: true,
    icon: icon3,
    iconActive: icon3Active,
    id: 201,
    name: "供需管理",
    path: "/business-manage/supply-demand-manage",
    sequence: 2,
    superiorId: 2,
    type: "MENU",
  },
  {
    actionKey: "menu:received-inquiry-list",
    componentPath: "business/my-inquiry/InquiryPage",
    enabled: true,
    icon: icon4,
    iconActive: icon4Active,
    id: 203,
    name: "询盘管理",
    path: "/business-manage/received-inquiry",
    sequence: 3,
    superiorId: 2,
    type: "MENU",
  },
  {
    actionKey: "menu:message-manage",
    componentPath: "business/message-manage/MessagePage",
    enabled: true,
    icon: icon5,
    iconActive: icon5Active,
    id: 204,
    name: "咨询管理",
    path: "/business-manage/message-manage",
    sequence: 4,
    superiorId: 2,
    type: "MENU",
  },
  // 我的
  // {
  //   actionKey: "menu:like-manage",
  //   componentPath: "my/like/LikePage",
  //   enabled: true,
  //   icon: icon6,
  //   iconActive: icon6Active,
  //   id: 301,
  //   name: "我的点赞",
  //   path: "/my/my-like",
  //   sequence: 1,
  //   superiorId: 3,
  //   type: "MENU",
  // },
  {
    actionKey: "menu:collection-manage",
    componentPath: "my/collection/CollectionPage",
    enabled: true,
    icon: icon7,
    iconActive: icon7Active,
    id: 302,
    name: "我的收藏",
    path: "/my/my-collection",
    sequence: 2,
    superiorId: 3,
    type: "MENU",
  },
  // {
  //   actionKey: "menu:profile-info",
  //   componentPath: "my/info/ProfileInfo",
  //   enabled: true,
  //   icon: icon8,
  //   iconActive: icon8Active,
  //   id: 303,
  //   name: "基本信息",
  //   path: "/my/info",
  //   sequence: 3,
  //   superiorId: 3,
  //   type: "MENU",
  // },
];
