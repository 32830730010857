import request from "./index";

// export function login(data) {
//   return request({
//     url: "authentication",
//     method: "post",
//     data,
//   });
// }

export function getUserInfoByToken() {
  return request({
    url: "/web/login/byExhibitorToken",
    method: "post",
  });
}

export function login(data) {
  return request({
    url: "/web/login/byExhibitor",
    method: "post",
    data,
  });
}

export function getAccountInfo(id) {
  return request({
    url: `/account/${id}`,
    method: "get",
  });
}

export function getUserInfo(id) {
  return request({
    url: `/user/${id}`,
    method: "get",
  });
}

export function getOrganizationInfo(id) {
  return request({
    url: `/organization/${id}`,
    method: "get",
  });
}

export function updateAccountInfo(data) {
  return request({
    url: `/account/${data.id}`,
    method: "patch",
    data,
  });
}

export function changePassword(data) {
  return request({
    url: `/authentication/changePwd`,
    method: "post",
    data,
  });
}

//获取短信验证码
export function getSmsCode(data) {
  return request({
    url: `/authentication/phone-verification-code`,
    method: "post",
    data,
  });
}

//获取邮箱验证码
export function getEmailCode(data) {
  return request({
    url: `/authentication/email-verification-code`,
    method: "post",
    data,
  });
}

//修改密码接口
export function editPassword(data) {
  return request({
    url: `/authentication/changePwd`,
    method: "post",
    data,
  });
}

export function getUserDetailInfo() {
  return request({
    url: "/authentication/byToken",
    method: "get",
  });
}

/**
 * 生成微信公众号二维码
 *
 * @export
 * @return {*}
 */
export function getWechatQRCode() {
  return request({
    url: "/officialAccount/getOfficialAccountsCode",
    method: "get",
  });
}

export function updateExhibitorInfo(data) {
  return request({
    url: "/exhibitor",
    method: "post",
    data,
  });
}
