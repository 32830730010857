import { defineStore } from "pinia";
import { listToTree } from "@/utils/utils";
import Layout from "@/components/layout/BaseLayout";
import ParentView from "@/components/layout/ParentView.vue";
import { localRoutes } from "@/router/localRouter";

export const usePermissionStore = defineStore("permission", {
  state: () => ({
    menus: [],
    permissions: [],
    routes: [],
  }),
  getters: {
    headerMenus() {
      return this.menus;
    },
  },
  actions: {
    fetchMenus() {
      return new Promise((resolve) => {
        const list = localRoutes
          .filter(
            (item) =>
              item.type == "DIR" || item.type == "MENU" || item.type == "LINK"
          )
          .map((item) => ({
            ...item,
            path: item.path.startsWith("/") ? item.path : `/${item.path}`,
            parentId: item.superior ? item.superior.id : null,
            meta: {
              id: item.id,
              pid: item.superiorId,
            },
          }));
        const newList = [];
        list.forEach((item) => {
          if (!item.superior && item.type == "MENU") {
            const newNode = {
              id: item.id,
              enabled: true,
              sequence: 1,
              createTime: Date.now(),
              creator: null,
              updateTime: Date.now(),
              updater: null,
              actionKey: "root:root",
              componentPath: null,
              icon: item.icon,
              name: item.name,
              path: "/",
              redirect: item.path,
              type: "DIR",
              superior: null,
            };
            newList.push({
              ...item,
              superior: newNode,
            });
            newList.push({
              ...newNode,
            });
          } else {
            newList.push(item);
          }
        });

        newList.forEach((route) => {
          if (route.superior == null) {
            route.component = Layout;
          } else {
            if (route.type == "DIR" || route.componentPath == null) {
              route.component = ParentView;
            } else {
              route.component = () => import(`@/views/${route.componentPath}`);
            }
          }
        });

        // const routes = newList.toTree("superior", "children");
        const routes = listToTree(newList, "superiorId");
        console.log(routes);
        this.menus = routes;
        this.routes = newList;
        resolve({ list: newList, routes });
      });
    },
  },
});
