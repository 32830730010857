import dayjs from "dayjs";
import AreaData from "@/utils/area";

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

Array.prototype.toTree = function (a = "superior", b = "subordinates") {
  var map = {},
    tree = [];

  for (const item of this) {
    map[item.id] = item;
  }

  for (const node of this) {
    if (node[a] && node[a].id) {
      if (map[node[a].id] !== undefined) {
        if (!map[node[a].id][b]) {
          map[node[a].id][b] = [];
        }

        map[node[a].id][b].push(node);
      }
    } else {
      tree.push(node);
    }
  }

  return tree;
};

const listToTree = function (list, parentKey, parentId = null) {
  const tree = [];
  for (const item of list.filter((i) => i[parentKey] === parentId)) {
    const children = listToTree(list, parentKey, item.id);
    if (children.length) {
      item.children = children;
    }
    tree.push(item);
  }
  return tree;
};

const treeToList = (tree, childField = "children") => {
  let queen = [];
  const out = [];
  queen = queen.concat(tree);
  while (queen.length) {
    const first = queen.shift();
    if (first[childField]) {
      queen = queen.concat(first[childField]);
      // delete first[childField];
    }
    out.push(first);
  }
  return out;
};

const formatTime = (datetime, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!datetime) {
    return "/";
  }
  return dayjs(datetime).format(format);
};

const downloadFile = (obj, name, suffix) => {
  const url = window.URL.createObjectURL(new Blob([obj]));
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  const fileName = name + "." + suffix;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const isClickable = (val) => val !== "" && val !== null && val !== undefined;

const renderArea = (region) => {
  if (!region) {
    return null;
  }
  const [province, city, area] = region.splice(",");
  const provinceObject = AreaData.find((item) => item.value == province);
  const cityObject = provinceObject.children.find((item) => item.value == city);
  return {
    province: provinceObject.label,
    city: cityObject.label,
    area: cityObject.children.find((item) => item.value == area).label,
  };
};

const getImageSize = (url) => {
  const img = new Image();
  img.src = url;
  console.log(img);
  return {
    width: img.width,
    height: img.height,
  };
};

const getChildUrl = (route) => {
  if (Array.isArray(route.children) && route.children.length) {
    return getChildUrl(route.children[0]);
  } else {
    return route.path;
  }
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export {
  treeToList,
  formatTime,
  downloadFile,
  isClickable,
  renderArea,
  getImageSize,
  getChildUrl,
  getParentKey,
  listToTree,
};
