<template>
  <a-menu-item class="flex" :key="item.id" @click.capture.stop="handleLink">
    <!-- <span class="anticon" v-if="routeChildren">
      <icon :icon="routeChildren.meta.icon"></icon>
    </span>
    <div class="round" v-else></div> -->
    <span class="icon-title-box">
      <img
        v-if="routeChildren.icon && routeChildren.iconActive"
        :src="active ? routeChildren.iconActive : routeChildren.icon"
        style="height: 18px; width: 18px"
      />
      <span class="menu-title">{{ routeChildren.name }}</span>
    </span>
  </a-menu-item>
</template>

<script>
export default {
  name: "MenuItem",
  data() {
    return {
      active: false,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    routeChildren: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    "$route.path": function (val) {
      this.active = val === this.routeChildren.path;
    },
  },
  created() {
    this.active = this.$route.path === this.routeChildren.path;
  },
  methods: {
    handleLink() {
      const route = this.routeChildren;
      const { type, path, redirect } = route;
      console.log(this.$router.getRoutes());
      if (type == "LINK") {
        window.open(path);
      } else if (type == "MENU") {
        this.$router.push(route.path);
      } else {
        if (type == "DIR" && path == "/") {
          this.$router.push(redirect);
        } else {
          console.log();
        }
      }
    },
  },
};
</script>
