<template>
  <a-config-provider :locale="locale" :autoInsertSpaceInButton="false">
    <template #renderEmpty>
      <div style="text-align: center; margin-top: 50px">
        <img
          src="../src/assets/images/empty-data.png"
          style="max-width: 100%"
        />
      </div>
    </template>
    <router-view />
  </a-config-provider>
</template>

<script setup>
import { watch, onMounted } from "vue";
// import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";

import zhCN from "ant-design-vue/es/locale/zh_CN";

const locale = zhCN;

const store = useUserStore();
const route = useRoute();
// const store = useStore();

// const isLogin = ref(false);

// const loadRoutes = () => {
//   store.dispatch("permission/setRoutes");
// };

const fetchUserInfo = async () => {
  store.token && store.fetchUserInfo();
};

const getIp = () => {
  fetch("https://api.ipify.org/")
    .then((res) => res.text())
    .then((data) => {
      console.log(data);
      sessionStorage.setItem("ip", data);
    });
};

watch(
  () => route.name,
  () => {
    if (route.name == "login") {
      document.documentElement.classList.remove("light-form-wrapper");
    } else {
      document.documentElement.classList.add("light-form-wrapper");
    }
    // isLogin.value = route.name == "login";
  }
);

watch(
  () => route.path,
  (params) => {
    // const { token } = route.query;
    // if (token) {
    //   setToken(token);
    // }
    fetchUserInfo();
    console.log(params);
  }
);

onMounted(() => {
  getIp();
  // loadRoutes();
});
</script>

<style lang="less"></style>
