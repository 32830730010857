<template>
  <a-space :size="15">
    <a-button type="primary" @click="handleSearch">
      <!-- <template #icon>
        <SearchOutlined />
      </template> -->
      查询</a-button
    >
    <a-button class="ant-btn-reset" type="default" @click="handleReset">
      <!-- <template #icon>
        <ReloadOutlined />
      </template> -->
      重置</a-button
    >
    <a-button type="primary" @click="handleExport" v-if="exportVisible">
      <!-- <template #icon>
        <DownloadOutlined />
      </template> -->
      导出</a-button
    >
    <slot></slot>
  </a-space>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
// import {
//   SearchOutlined,
//   ReloadOutlined,
//   DownloadOutlined,
// } from "@ant-design/icons-vue";

defineProps({
  exportVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["search", "reset", "export"]);

const handleSearch = () => {
  emit("search");
};

const handleReset = () => {
  emit("reset");
};

const handleExport = () => {
  emit("export");
};
</script>
