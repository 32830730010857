<template>
  <a-select
    :allowClear="allowClear"
    :mode="mode"
    :showSearch="showSearch"
    :options="options"
    :filter-option="filterOption"
    @change="handleChange"
    style="width: 160px"
  >
  </a-select>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, onMounted } from "vue";
import { fetchSelectDataSource } from "@/api/common";

const props = defineProps({
  allowClear: {
    type: Boolean,
    default: true,
  },
  // 是否立即执行
  immediate: {
    type: Boolean,
    default: true,
  },
  // mode,同 ant-design-vue
  mode: {
    type: String,
  },
  // 是否显示全部
  showAll: {
    type: Boolean,
  },
  // 是否启用搜索
  showSearch: {
    type: Boolean,
    default: true,
  },
  // 数据源接口 URL
  url: {
    type: String,
    required: true,
  },
  // 请求接口携带参数
  params: {
    type: Object,
  },
  // 自定义节点 label、value、options 的字段
  fieldNames: {
    type: Object,
    default: () => ({
      label: "name",
      value: "id",
    }),
  },
});

const emit = defineEmits(["change", "choose"]);

const dataSource = ref([]);
const options = ref();

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const handleChange = (val) => {
  emit("change", val);
  if (Array.isArray(val)) {
    const list = val.map((item) => {
      return dataSource.value.find((i) => i[props.fieldNames["value"]] == item);
    });
    console.log(list);
    emit("choose", list);
  } else {
    const item = dataSource.value.find(
      (i) => i[props.fieldNames["value"]] == val
    );
    console.log(item);
    emit("choose", item);
  }
};

const fetchDataSource = async () => {
  const res = await fetchSelectDataSource(props.url, props.params);
  const resList = res.data || res;
  dataSource.value = resList;
  const keys = props.fieldNames;
  const list = resList.map((item) => ({
    label: item[keys["label"]],
    value: item[keys["value"]],
  }));
  if (props.showAll) {
    list.unshift({
      label: "全部",
      value: null,
    });
  }
  options.value = list;
};

onMounted(() => {
  if (props.showAll) {
    options.value = [
      {
        label: "全部",
        value: null,
      },
    ];
  }
  if (props.immediate) {
    fetchDataSource();
  }
});

watch(
  () => props.params,
  () => {
    fetchDataSource();
  }
);

watch(
  () => props.url,
  () => {
    fetchDataSource();
  }
);
</script>
