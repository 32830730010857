import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";

import hasPermi from "@/directive/permission/hasPermi";
import visible from "@/directive/visible.js";

import App from "./App.vue";
import router from "./router";

import PageTitle from "@/components/page/PageTitle.vue";
import PagePanel from "@/components/page/PagePanel.vue";
import PageView from "@/components/layout/PageView";
import SearchFormButtons from "@/components/layout/SearchFormButtons.vue";
import SearchWrapper from "@/components/layout/SearchWrapper";
import YButton from "@/components/common/YButton";
import YTable from "@/components/common/YTable";
import YSelect from "@/components/common/YSelect";
import YUpload from "@/components/common/YUpload";
import YSelectOrganizationTree from "@/components/common/YSelectOrganizationTree";
import YTableDeleteButton from "@/components/common/table/YTableDeleteButton";
import YImage from "@/components/common/YImage.vue";
import YRichtext from "@/components/common/YRichtext";
// import SvgIcon from "@/components/icon-select/SvgIcon.vue";
import SvgIcon from "@/components/icon-select/SvgIcon.vue";

import "@/icons/index";
import "@/assets/css/index.less";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.directive("hasPermi", hasPermi);
app.directive("visible", visible);

app.component("PageTitle", PageTitle);
app.component("PagePanel", PagePanel);
app.component("PageView", PageView);
app.component("SearchWrapper", SearchWrapper);
app.component("YButton", YButton);
app.component("YTable", YTable);
app.component("YUpload", YUpload);
app.component("YSelect", YSelect);
app.component("YSelectOrganizationTree", YSelectOrganizationTree);
app.component("SearchFormButtons", SearchFormButtons);
app.component("YTableDeleteButton", YTableDeleteButton);
app.component("YImage", YImage);
app.component("SvgIcon", SvgIcon);
app.component("YRichtext", YRichtext);

app.use(Antd).use(pinia).use(router).mount("#app");
