<template>
  <div
    class="y-button"
    :class="{
      disabled,
      ghost,
    }"
    :type="type"
    @click.stop.capture="handleClick"
  >
    <div class="button-box">
      <img src="../../assets/images/button/add.png" v-if="iconType == 'add'" />
      <img
        src="../../assets/images/button/delete.png"
        v-if="iconType == 'delete'"
      />
      <img
        src="../../assets/images/button/export.png"
        v-if="iconType == 'export'"
      />
      <img
        src="../../assets/images/button/import.png"
        v-if="iconType == 'import'"
      />
      <img
        src="../../assets/images/button/download-template.png"
        v-if="iconType == 'template'"
      />
      <img
        src="../../assets/images/button/filter.png"
        v-if="iconType == 'filter'"
      />
      <img
        src="../../assets/images/button/reset.svg"
        v-if="iconType == 'reset' && type !== 'active'"
      />
      <img
        src="../../assets/images/button/reset-active.png"
        v-if="iconType == 'reset' && type === 'active'"
      />
      <slot></slot>
    </div>
    <a-modal
      :visible="confirmModalShow"
      title="提示"
      @ok="confirmDelete"
      @cancel="confirmModalShow = false"
    >
      <p>确认删除数据吗？</p>
    </a-modal>
  </div>
  <!-- <div class="btn y-btn" @click="handleClick">
    <a-icon type="loading" v-if="loading" style="margin-right: 8px" />
    <slot name="icon"></slot>
  </div> -->
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    ghost: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "default",
    },
    textType: {
      type: String,
      default: "primary",
    },
    iconType: {
      type: String,
      validator(value) {
        return [
          "add", // 新增
          "delete", // 删除
          "export", // 导出
          "import", // 导入
          "template", // 下载模板
          "filter", // 筛选
          "reset", // 重置
        ].includes(value);
      },
    },
  },
  data() {
    return {
      confirmModalShow: false,
    };
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        return;
      }
      if (this.iconType === "delete") {
        this.confirmModalShow = true;
        return;
      }
      this.$emit("click");
    },
    confirmDelete() {
      this.$emit("click");
      this.confirmModalShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.y-button {
  cursor: pointer;
  display: inline-block;
  padding: 0 15px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-sizing: border-box;
  .button-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  &.ghost {
    border: 0;
    padding: 0 15px;
  }
}
[type="primary"] {
  background: var(--yy-primary-color);
  color: #fff;
  border-color: currentColor;
}
[type="delete"] {
  background: var(--yy-error-color);
  color: #ffffff;
  border-color: currentColor;
}
[type="active"] {
  color: var(--yy-primary-color);
  img {
    width: 14px;
    height: 14px;
  }
}

// .btn {
//   width: 92px;
//   margin: 0 12px;
//   display: inline-block;
//   cursor: pointer;
//   height: 48px;
//   padding: 0 14px;
//   text-align: center;
//   line-height: 48px;
//   background: #35448f;
//   box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
//   border-radius: 8px;
//   font-size: 18px;
//   font-family: PingFang SC-Medium, PingFang SC;
//   font-weight: 500;
//   color: #ffffff;
//   img {
//     vertical-align: sub;
//   }
// }

// .y-btn-primary {
//   background: #157efb;
// }

// .y-btn-text {
//   font-family: "PingFang SC";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
//   height: auto;
//   width: auto;
//   padding: 0;
//   background: transparent;
//   box-shadow: none;
//   border-radius: 0;
//   &-primary {
//     color: #157efb;
//   }
//   &-success {
//     color: #41ca5f;
//   }
//   &-disable {
//     color: #cccccc;
//   }
// }

// .y-btn-loading {
//   opacity: 0.8;
//   pointer-events: none;
// }

// .y-btn-light {
//   height: 48px;
//   background: #ffffff;
//   box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
//   border-radius: 12px;
//   font-family: "PingFang SC";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   color: #2979ff;
// }
</style>
