import qs from "qs";
import request from "@/api/index";

/**
 * 获取 Select 组件数据源
 *
 * @export
 * @param {*} url
 * @param {*} params
 * @return {*}
 */
export function fetchSelectDataSource(url, params) {
  let requestUrl = url;
  let q = "";
  if (params) {
    q = qs.stringify(params, { arrayFormat: "indices", allowDots: true });
    requestUrl = `${requestUrl}?${q}`;
  }
  return request({
    url: requestUrl,
    method: "get",
  });
}
