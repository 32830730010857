<template>
  <header class="page-header">
    <div class="left flex">
      <!-- <img
        style="width: 30px; height: 30px; cursor: pointer; margin-right: 20px"
        src="../../assets/images/icon-menu.png"
        alt=""
      /> -->
      <img src="../../assets/images/header/logo-new.png" />
      <span class="title" role="button" style="margin: 0 10px"
        >展商管理后台</span
      >
      <div class="navs">
        <div
          class="nav"
          :class="{
            active: index === selectedHeaderMenuIndex,
          }"
          role="button"
          v-for="(nav, index) in headerMenus"
          :key="nav.id"
          @click="changeHeaderMenu(index)"
        >
          <img class="icon" />
          <div class="lable">{{ nav.name }}</div>
        </div>
      </div>
    </div>
    <div class="header-right">
      <!--<img src="../../assets/images/header/message.png" class="icon-message" />-->
      <a-dropdown>
        <div class="profile" role="button">
          <img
            :src="companyInfo.properties.logo"
            class="icon-avatar"
            role="button"
            v-if="
              companyInfo &&
              companyInfo.properties &&
              companyInfo.properties.logo
            "
          />
          <img
            src="../../assets/images/header/avatar.png"
            class="icon-avatar"
            role="button"
            v-else
          />
          <span class="username">{{ userInfo && userInfo.name }}</span>
          <DownOutlined style="color: #8791a3" />
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a href="javascript:;" @click="logout">登出</a>
            </a-menu-item>
            <!--<a-menu-item>-->
            <!--  <a href="javascript:;" @click="toBaseInfoPage">基本信息</a>-->
            <!--</a-menu-item>-->
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </header>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "pinia";
import { Modal } from "ant-design-vue";
import { DownOutlined } from "@ant-design/icons-vue";

import { setToken } from "@/utils/auth";

import { useUserStore } from "@/stores/user";
import { usePermissionStore } from "@/stores/permission";
import mitt from "@/utils/mitt";

export default {
  data() {
    const dateLabel = dayjs().format(`dddd, MMM D, YYYY`);
    return {
      dateLabel,
      userName: "",
      headerMenus: [],
      companyInfo: {},
    };
  },
  components: {
    DownOutlined,
  },
  computed: {
    ...mapState(useUserStore, ["userInfo"]),
    // ...mapState(usePermissionStore, ["menus"]),
    selectedHeaderMenuIndex() {
      let selectedIndex;
      const path = this.$route.path;
      this.headerMenus.forEach((item, index) => {
        if (path.startsWith(item.path)) {
          selectedIndex = index;
        }
      });
      return selectedIndex;
    },
  },
  mounted() {
    this.getHeaderMenus();
    mitt.on("refreshHeaderMenus", () => {
      this.getHeaderMenus();
    });
    mitt.off("refreshHeaderMenus");

    const store = useUserStore();
    this.companyInfo = { ...store.exhibitorInfo };
    console.log(this.companyInfo);
  },
  methods: {
    getHeaderMenus() {
      const store = usePermissionStore();
      this.headerMenus = store.headerMenus;
    },
    goBack() {
      this.$router.go(-1);
    },
    logout() {
      const store = useUserStore();
      const permissionStore = usePermissionStore();
      const _this = this;
      Modal.confirm({
        title: "退出",
        content: "确定退出系统吗？",
        onOk() {
          store.$reset();
          permissionStore.$reset();
          setToken("");
          _this.$router.push("/login");
        },
        onCancel() {},
      });
    },
    changeHeaderMenu(index) {
      if (!this.headerMenus[index]?.children.length) {
        return;
      }
      const path = this.headerMenus[index].children[0].path;
      this.$router.push({
        path: path,
      });
    },
    toBaseInfoPage() {
      this.$router.push("/my/info");
    },
    toPasswordPage() {
      this.$router.push("/my/password");
    },
  },
};
</script>

<style lang="less" scoped>
.page-header {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;
  display: flex;
  background: #fff;
  position: relative;
  z-index: 100;
  box-shadow: 0px 0.5px 2px 0px rgba(0, 0, 0, 0.1);
  .left {
    flex: 1;
    align-items: center;
    .title {
      color: #1b1b25;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 25.2px */
    }

    .navs {
      margin-left: 66px;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 36px;
      .nav {
        display: flex;
        align-items: center;
        color: #374561;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        padding: 11px 8px;
        box-sizing: border-box;
        .label {
          margin-left: 10px;
        }
        &.active {
          color: #00a4f5;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          border-radius: 6px;
          background: #f0f9fe;
        }
      }
    }
  }
  .header-right {
    color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    .nav {
      margin-left: 12px;
      display: flex;
    }
    .date {
      padding-right: 13px;
      border-right: 1px solid #fff;
    }

    .text {
      line-height: 24px;
    }
    .icon {
      margin-right: 4px;
    }
  }
}

.important-tooltip {
  animation: imp 3s;
  animation-iteration-count: infinite;
}

.header-right {
  display: flex;
  align-items: center;
  .icon-message {
    width: 24px;
    height: 24px;
  }
  .profile {
    margin-left: 30px;
    display: flex;
    align-items: center;
    .username {
      margin: 0 8px;
      color: #8791a3;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.icon-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
</style>

<style>
@keyframes imp {
  0% {
    color: red;
  }
  25% {
    color: rgb(0, 255, 38);
  }
  50% {
    color: yellow;
  }
  75% {
    color: rgb(201, 4, 109);
  }
  100% {
    color: khaki;
  }
}
</style>
