<template>
  <a-sub-menu :key="item.id">
    <template v-slot:title>
      <span class="icon-title-box">
        <img
          v-if="item.icon && item.iconActive"
          :src="active ? item.iconActive : item.icon"
          style="height: 18px; width: 18px"
        />
        <span>{{ item.name }}</span>
      </span>
    </template>
    <slot></slot>
  </a-sub-menu>
</template>

<script>
export default {
  name: "SubMenu",
  data() {
    return {
      active: "",
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    routeChildren: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    "$route.path": {
      handler(val) {
        this.active = val === this.item.path;
      },
      immediate: true,
    },
  },
  created() {
    this.active = this.$route.path === this.item.path;
  },
};
</script>

<style lang="less" scoped>
// .menu-anticon {
//   position: relative;
//   top: -3px;
//   display: inline-block;
//   width: 28px;
//   line-height: 28px;
//   z-index: 2;
//   background-color: transparent;
// }
// .ant-menu-submenu-selected {
//   .menu-anticon {
//     // background-color: #1981c9;
//   }
// }
// .anticon {
//   img {
//     position: relative;
//   }
// }
// .active {
//   position: absolute;
//   top: 12px;
//   left: 0;
//   width: 30px;
//   height: 30px;
//   background-color: #1981c9;
//   border-radius: 3px;
// }
</style>
