<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  prefix: {
    type: String,
    default: "icon",
  },
  icon: {
    type: String,
    required: false,
  },
  color: {
    type: String,
  },
  size: {
    type: String,
    default: "1em",
  },
});

const symbolId = computed(() => `#${props.prefix}-${props.icon}`);
</script>

<template>
  <svg :aria-hidden="true" class="svg-icon">
    <use :xlink:href="symbolId" />
  </svg>
</template>

<style scoped lang="less">
.svg-icon {
  overflow: hidden;
  fill: currentColor;
}
</style>
