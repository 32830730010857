<template>
  <a-tree-select
    show-search
    placeholder="请选择组织"
    tree-node-filter-prop="label"
    style="width: 160px"
    :disabled="disabled"
    :value="value"
    :tree-data="options"
    :allowClear="allowClear"
    :field-names="{
      children: 'subordinates',
      label: 'name',
      value: 'id',
    }"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    @change="onChange"
  >
  </a-tree-select>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue";

import { getList } from "@/api/organization";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  allowClear: {
    type: Boolean,
    default: true,
  },
  showAll: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:value", "change"]);

const options = ref([]);

const fetchList = async () => {
  const res = await getList();
  const list = [...res.content];
  if (props.showAll) {
    list.unshift({
      name: "全部",
      id: null,
      subordinates: null,
    });
  }
  options.value = list;
};

const onChange = (val) => {
  emit("change");
  emit("update:value", val);
};

onMounted(() => {
  fetchList();
});
</script>
