<template>
  <a-upload
    :action="action"
    :accept="accept"
    :file-list="fileList"
    :list-type="listType"
    :showUploadList="showUploadList"
    :headers="{
      'X-API-KEY': 123456,
    }"
    @change="handleChange"
    @preview="handlePreview"
  >
    <template v-if="fileList.length < maxCount"
      ><div v-if="listType == 'picture-card'">
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
        <div class="ant-upload-text">上传</div>
      </div>
      <a-button type="default" v-if="listType == 'text'" :loading="loading"
        ><upload-outlined></upload-outlined>上传</a-button
      ></template
    >
  </a-upload>
  <a-modal
    :visible="previewVisible"
    :title="previewTitle"
    :footer="null"
    @cancel="handleCancel"
  >
    <img alt="example" style="width: 100%" :src="previewImage" />
  </a-modal>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useUserStore } from "@/stores/user";
import {
  PlusOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
import { uuid } from "@/utils/utils";

const action = process.env.VUE_APP_API_BASE_URL + "/file";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  components: {
    LoadingOutlined,
    PlusOutlined,
    UploadOutlined,
  },
  props: {
    value: {
      type: [String, Array],
    },
    accept: {
      type: String,
      default: "image/*",
    },
    showUploadList: {
      type: Boolean,
      default: true,
    },
    maxCount: {
      type: Number,
      default: 1,
    },
    listType: {
      type: String,
      default: "picture-card",
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const loading = ref(false);
    const store = useUserStore();
    const files = ref([]);
    const fileList = ref([]);
    const token = computed(() => store.token);
    const headers = ref({
      Authorization: `Bearer ${token.value}`,
    });
    const previewVisible = ref(false);
    const previewImage = ref("");
    const previewTitle = ref("");

    const handleChange = (info) => {
      const files = info.fileList;
      fileList.value = files;
      if (files.length === 0) {
        emit("update:value", []);
      } else {
        if (files[files.length - 1].status === "done") {
          emit(
            "update:value",
            files.map((item) => item.url || item.response.url)
          );
        }
      }
    };

    const handleCancel = () => {
      previewVisible.value = false;
      previewTitle.value = "";
    };

    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
      previewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
    };

    const getNameByUrl = (url) => {
      return url.split("/file/")[1];
    };

    const generateFileList = (urls) => {
      fileList.value = urls.map((url) => {
        return {
          url,
          name: getNameByUrl(url),
          id: uuid(),
        };
      });
    };

    watch(
      () => props.value,
      (val) => {
        if (Array.isArray(val)) {
          if (val.length) {
            generateFileList(val);
          } else {
            fileList.value = [];
          }
        } else {
          if (val) {
            generateFileList([val]);
          } else {
            files.value = [];
            fileList.value = [];
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      loading,
      action,
      headers,
      files,
      fileList,
      previewVisible,
      previewImage,
      previewTitle,
      handleChange,
      handlePreview,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
