import { createRouter, createWebHistory } from "vue-router";
import { message } from "ant-design-vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { useUserStore } from "@/stores/user";
import { usePermissionStore } from "@/stores/permission";
import { getChildUrl } from "@/utils/utils";

// import Layout from "@/components/layout/BaseLayout";
// import SubBaseLayout from "@/components/layout/SubBaseLayout";

export const constantRoutes = [
  {
    path: "/",
    redirect: "/loading",
  },
  {
    path: "/login",
    name: "login",
    hidden: false,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    // import(/* webpackChunkName: "login" */ "../views/login-new/LoginNew.vue"),
  },
  {
    path: "/loading",
    name: "Loading",
    hidden: true,
    component: () =>
      import(/* webpackChunkName: "loading" */ "../views/Loading.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...constantRoutes],
});

const whiteList = [
  "/login",
  "/auth-redirect",
  "/bind",
  "/register",
  "/loading",
];

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const permissionStore = usePermissionStore();
  NProgress.start();
  if (to.query.from == "forestage") {
    userStore.setToken(to.query.token);
  }
  const token = userStore.token;
  if (token) {
    if (to.path == "/login") {
      next();
      NProgress.done();
    } else {
      if (permissionStore.menus.length == 0) {
        permissionStore.fetchMenus().then(({ list: routes, routes: menus }) => {
          const pageRoutes = routes.filter((item) => item.type == "MENU");
          if (!pageRoutes.length) {
            message.error("暂未配置权限");
            next({
              path: "/login",
            });
          } else {
            menus.forEach((route) => {
              router.addRoute(route);
            });
            const first = menus[0];
            const path = getChildUrl(first);
            const firstTo = { path };
            console.log(firstTo);
            // const firstTo =
            //   Array.isArray(first.children) && first.children.length
            //     ? menus[0].children[0].path
            //     : first.path;
            if (from.path == "/login") {
              if (to.path == "/loading") {
                next({ ...to });
              } else {
                next({ path: firstTo.path, replace: true });
              }
            } else {
              if (
                (from.query?.from == "forestage" && to.path == "/loading") ||
                to.query?.from == "forestage"
              ) {
                if (to.query.url) {
                  next({
                    ...to,
                    replace: true,
                  });
                } else {
                  next({ path: firstTo.path, replace: true });
                }
              } else {
                next({
                  ...to,
                  replace: true,
                });
              }
            }
          }
        });
      } else {
        next();
        NProgress.done();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) != -1) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  }
  NProgress.done();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
