import { defineStore } from "pinia";

import { login, getUserDetailInfo, getUserInfoByToken } from "@/api/auth";
import { getBasicInfo } from "@/api/expo/company";

export const useUserStore = defineStore("user", {
  persist: {
    storage: sessionStorage,
  },
  state: () => ({
    token: "",
    userInfo: {},
    organizationInfo: {},
    exhibitorInfo: {},
  }),
  actions: {
    setToken(token) {
      this.token = token;
    },
    logout() {
      this.$reset();
    },
    resetAccount(account) {
      this.accountInfo = account;
    },
    refreshAccount(account) {
      this.accountInfo.name = account.name;
      const properties = this.accountInfo.properties || {};
      properties.avatar = account.avatar;
      this.accountInfo.properties = properties;
    },
    onLogin(params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then((res) => {
            const { token, user, exhibitor } = res.data;
            this.token = token;
            console.log(user);
            console.log(exhibitor);
            // this.userInfo = user;
            // this.exhibitorInfo = exhibitor;
            resolve(res);
            // this.getInfo(res);
          })
          .catch((error) => reject(error));
      });
    },
    getExhibitorInfo(id) {
      getBasicInfo(id).then((res) => {
        this.exhibitorInfo = res;
      });
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        getUserDetailInfo()
          .then((res) => {
            this.userInfo = res;
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
    getUserDetail() {
      return getUserDetailInfo();
    },
    setExhibitorInfo(info) {
      this.exhibitorInfo = info;
    },
    fetchUserInfo() {
      return new Promise((resolve, reject) => {
        getUserInfoByToken()
          .then((res) => {
            const { token, user, exhibitor } = res.data;
            this.token = token;
            this.userInfo = user;
            this.exhibitorInfo = exhibitor;
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
  },
});
