import { ref } from "vue";
import { message } from "ant-design-vue";
import screenfull from "screenfull";
// 封装：整个页面、元素切换全屏
const useScreenfullEffect = () => {
  const isFullScreenTag = ref(false);
  // 检测当前页面是否全屏，如果是全屏就退出，否则就全屏
  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull.toggle();
        isFullScreenTag.value = false;
        // screenfull.exit()
      } else {
        // 进入全屏
        screenfull.toggle();
        isFullScreenTag.value = true;
        // screenfull.request()
      }
    } else {
      message.error({
        content: "当前浏览器不支持全屏",
        duration: 2,
      });
    }
  };
  // 点击当前元素进入全屏，一般为图片
  const handleFullscreenElement = (element) => {
    if (screenfull.isEnabled) {
      screenfull.toggle(element);
    } else {
      message.error({
        content: "当前浏览器不支持全屏",
        duration: 2,
      });
    }
  };
  return { isFullScreenTag, handleFullScreen, handleFullscreenElement };
};

export default {
  useScreenfullEffect,
};
