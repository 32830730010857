<template>
  <div class="page-panel">
    <div class="page-title">
      <div class="text" v-if="pageTitle && !hideTitle">{{ pageTitle }}</div>
      <div class="extra">
        <slot name="extra"> </slot>
        <FullscreenOutlined @click="handleFullScreen" v-if="showFullScreen" />
      </div>
    </div>
    <div class="table-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useRoute } from "vue-router";
import { FullscreenOutlined } from "@ant-design/icons-vue";
import hooks from "@/utils/hooks";
const { handleFullscreenElement } = hooks.useScreenfullEffect();
const route = useRoute();
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  showFullScreen: {
    // 是否显示全屏按钮
    type: Boolean,
    default: false,
  },
  hideTitle: {
    // 是否隐藏标题栏
    type: Boolean,
    default: false,
  },
});
const handleFullScreen = () => {
  handleFullscreenElement(document.querySelector(".app-main"));
};
const pageTitle = props.title ?? ref(route.name);
</script>

<style lang="less" scoped>
.page-panel {
  padding: 16px 24px;
  background: #fff;
  .page-title {
    display: flex;
    justify-content: space-between;
    .text {
      color: #374561;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
    }
    .extra {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      ::v-deep .anticon {
        cursor: pointer;
      }
    }
  }
  .table-wrapper {
    margin-top: 18px;
  }
}
</style>
